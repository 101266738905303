import React from 'react'
import * as classess from './header.module.css'
import logo from './static/logo.png'
import logo_webp from './static/logo.webp'
import {Link} from 'gatsby'
const Header = () => {
    const handleCollapse = (e) => {
        let elem = document.getElementById('navbarSupportedContent')
        elem.classList.toggle("show")
    }
    return <div>
        <nav className={classess.navbar_background + " navbar navbar-expand-lg navbar-light"}>
            <a className="navbar-brand" href="#">
                <picture>
                    <source data-srcSet={logo_webp} type="image/webp" />
                    <source data-srcSet={logo} type="image/png" />
                    <img data-srcSet={logo_webp}  alt="Botnostic Solutions" loading="lazy" className={classess.header_logo_styling} />

                </picture>
            </a>
            <button className={classess.collapse_button + " navbar-toggler"} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={handleCollapse}>
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className={classess.list_positioning + " collapse navbar-collapse"} id="navbarSupportedContent">
                    <ul className= "navbar-nav mr-auto " >
                        <li className="nav-item" >
                            <a className="nav-link" href="http://mycareerdreams.com/recruitment-bot/" style={{color:'white'}}>
                                Home
                                </a>
                        </li>
                    </ul>
             


            </div>
        </nav>
    </div>
}
export default Header