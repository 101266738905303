import * as React from "react"
import Index from "../components/index"
import SEO from "../components/seo"

const IndexPage = () => (
    <React.Fragment>
        
        <SEO title="Home" />
        <Index/>

    </React.Fragment>
      


)

export default IndexPage
