import { Link } from 'gatsby'
import React, { useEffect } from 'react'
import Footer from '../footer/footer'
import Header from '../header/header'
import AllBlogs from '../recruitment_blogs/all_blogs'
import * as classess from './index.module.css'
import loading_gif from './static/loading.gif'
import Scroll from '../recruitment_blogs/blogs/scroll/scroll'


const Index = () => {
    const [allBlogsPublished, getAllBlogsPublished] = React.useState('')
    const [loading,showLoading] = React.useState(true)

    
    useEffect(() => {
        async function loadAllBlogs() {
            let temp = []
            Object.keys(AllBlogs).map((blog_name, index) => {
                return temp.push(<div key={index} className={classess.blogs_div}>
                      <Scroll showBelow={250} />
                <Link to={AllBlogs[blog_name]['blg_url']} className="link_styling">
                    <div>
                    <picture>
                        <source data-srcSet={AllBlogs[blog_name]['blg_im_webp']} type="image/webp" />
                        <source data-srcSet={AllBlogs[blog_name]['blg_img']} type="image/png" />
                        <img data-srcSet={AllBlogs[blog_name]['blg_im_webp']} alt="Main Image" loading="lazy" className={classess.image1_setting} />
                    </picture>
                        {/* <img src={AllBlogs[blog_name]['blg_img']} className={classess.image1_setting} /> */}
                        <h4 className={classess.medium_heading}>{blog_name}</h4>
                        <h6 className={classess.date_setting}>{AllBlogs[blog_name]['publish_date']}</h6><p className={classess.all_recruiting2}>
                            <Link to={AllBlogs[blog_name]['blg_url']} className="link_styling">
                                Blog
                            </Link>
                            </p>
                        <h6 className={classess.olivia}> By {AllBlogs[blog_name]['published_by']}</h6>
                    </div>
                </Link>
                </div>)
            })
            getAllBlogsPublished(temp)
            showLoading(false)
        }
        loadAllBlogs()
     
    }, [])
    return <React.Fragment>
        <Header />
        <div className={classess.main_div}>
            {
                loading === true?<center><img src={loading_gif} alt="Loading"className={classess.loading_gif}/></center>
                :
                allBlogsPublished
            }
        </div>
        {
            loading===false? <Footer/>:null
        }
       
    </React.Fragment>
}
export default Index