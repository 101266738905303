import pre_emp from './static/pre_employment.png'
import pre_emp_webp from './static/pre_employment.webp'
import quality_main from './static/quality_main.png'
import quality_main_webp from './static/quality_main.webp'
import avoid_cheating_img from './static/avoid_cheating_img.png'
import avoid_cheating_img_webp from './static/avoid_cheating_img.webp'
import hiring_img from './static/hiring_img.png'
import hiring_img_webp from './static/hiring_img.webp'
import time_to_hire from './static/ready_to_hire_banner.png'
import time_to_hire_webp from './static/ready_to_hire_banner.webp'
import bullet_header from './static/bullet_header.png'
import bullet_header_webp from './static/bullet_header.webp'
import conversational_banner from './static/conversational_banner.png'
import conversational_banner_webp from './static/conversational_banner.webp'
import hire_right_intern_img from './static/intern.png'
import hire_right_intern_webp from './static/intern_w.webp'
import biases_in_hiring_img from './static/hire.png'
import biases_in_hiring_webp from './static/hire_w.webp'



const AllBlogs = {
 

    "5 Types of Pre Employment Tests in 2021":{
        "blg_img":pre_emp,
        "blg_im_webp":pre_emp_webp,
        "publish_date":"June 6,2021",
        "blg_url":"/pre-employment-test/",
        "published_by":"Botnostic Solutions"
    },
    "Everything You Need to Know to Hire Quality Candidates":{
        "blg_img":quality_main,
        "blg_im_webp":quality_main_webp,
        "publish_date":"June 6,2021",
        "blg_url":"/quality-of-hire/",
        "published_by":"Botnostic Solutions"
    },
"How to Avoid Cheating in Virtual Pre Employment Tests in 2021":{
    "blg_img":avoid_cheating_img,
    "blg_im_webp":avoid_cheating_img_webp,
    "publish_date":"June 10,2021",
    "blg_url":"/cheating-preemployment-test/",
    "published_by":"Botnostic Solutions"
},
"10 Reasons You Shouldn’t be Using Resumes for Hiring | RecruitmentBot":{
    "blg_img":hiring_img,
    "blg_im_webp":hiring_img_webp,
    "publish_date":"June 11,2021",
    "blg_url":"/hiring-for-resumes/",
    "published_by":"Botnostic Solutions"
},
"How to Improve Time to Hire in 2021 | RecruitmentBot":{
    "blg_img":time_to_hire,
    "blg_im_webp":time_to_hire_webp,
    "publish_date":"June 22,2021",
    "blg_url":"/time-to-hire/",
    "published_by":"Botnostic Solutions"
},
"How to Design the Best Bulletproof Hiring Process in 2021":{
    "blg_img":bullet_header,
    "blg_im_webp":bullet_header_webp,
    "publish_date":"June 23,2021",
    "blg_url":"/bullet-proof-hiring/",
    "published_by":"Botnostic Solutions"
},
"5 Reasons to Switch to Conversational Recruiting in 2021?":{
    "blg_img":conversational_banner,
    "blg_im_webp":conversational_banner_webp,
    "publish_date":"June 25,2021",
    "blg_url":"/conversational-recruiting/",
    "published_by":"Botnostic Solutions"
},
"Hire the Right Intern":{
    "blg_img":hire_right_intern_img,
    "blg_im_webp":hire_right_intern_webp,
    "publish_date":"October 10,2021",
    "blg_url":"/hire-right-intern/",
    "published_by":"Botnostic Solutions"
},
"Biases in Hiring Process":{
    "blg_img":biases_in_hiring_img,
    "blg_im_webp":biases_in_hiring_webp,
    "publish_date":"October 18,2021",
    "blg_url":"/biases-in-hiring-process/",
    "published_by":"Botnostic Solutions"
}

} 
export default AllBlogs